.totalView{
    display: flex;
    flex-direction: row;
}
.firstView{
    margin-top: 20px;
}
.totalViewItem{
    display: flex;
    flex-direction: column;
    padding:10px;
    padding-left: 30px;
    padding-right: 30px;
    align-items: center;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
}
.totalViewItem:nth-child(2){
    border-right-width: 1px;
    border-left-width: 1px;
    border-left-style: solid;
    border-right-style: solid;
    border-left-color: #f5f5f5;
    border-right-color: #f5f5f5;
}
.valueText{
    font-weight: bold;
    margin-top: 8px;
}
.redText{
    color: red;
}
.greenText{
    color: green
}
.blueText{
    color: blue;
}
.grayText{
    color: gray;
}
.revenueViewHeader{
    padding-left: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 12px;
    margin-bottom: 12px;
    background-color: #f5f5f5;
}
.headerText{
    font-weight: bold;
}
.itemHeaderText{
    font-weight:600;
    color:gray;
}
