#root>div{
    width:100%!important;
    height:100%!important;
  }
  
  .feedback-submit-button,
  .add-feedback-button{
    text-transform:none!important;
    background-color: blue!important;
    padding: 10px!important;
    border-radius: 10px!important;
    color:white!important;
    margin-top:20px!important;
  }
  
  .add-feedback-dialog .MuiDialog-paper{
    width:50%;
  }
  
  @media(max-width:768px){
    .add-feedback-dialog .MuiDialog-paper{
      width:90%;
    }
  }
  
  .add-feedback-dialog .dialog-container{
    display:flex;
    flex-direction:column;
    align-items:center;
    padding:20px;
  }
  
  .feedback-item{
    box-sizing:border-box;
  }
  