#root>div{
    width:100%!important;
    height:100%!important;
  }
  
  .feedback-submit-button,
  .add-feedback-button{
    text-transform:none!important;
    background-color: blue!important;
    padding: 10px!important;
    border-radius: 10px!important;
    color:white!important;
    margin-top:20px!important;
  }
  
  .feedback-item{
    box-sizing:border-box;
  }
  
  .product-action-button{
    text-transform:none!important;
    background-color:orange!important;
    font-weight:600!important;
  }
  

  .dialog-container .MuiFormLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.54);
  }