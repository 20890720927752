.generic-dialog .MuiDialog-paper{
  width:50%;
}

@media(max-width:768px){
  .generic-dialog .MuiDialog-paper{
    width:90%;
  }
}

.generic-dialog .dialog-container{
  display:flex;
  flex-direction:column;
  align-items:center;
  padding:20px;
}

.create-product-button{
text-transform:none!important;
background-color: blue!important;
padding: 10px!important;
border-radius: 10px!important;
color:white!important;
margin-top:20px!important;
}
